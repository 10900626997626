<template>
  <div class="d-flex flex-column">
    <iframe
      v-if="!!_url"
      :src="_url"
      width="100%"
      height="500"
      frameborder="0"
      scrolling="auto"
      allowtransparency="true"
    />

    <div v-else class="d-flex text-center justify-center">
      <span class="text-h6 font-weight-light">
        Nenhum resultado foi encontrado
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    league: {
      type: null,
      required: true,
    },
  },

  computed: {
    _url() {
      return this.league.chalongeUrl;
    },
  },
};
</script>

<style></style>
